<template>
  <div class="social">
    <v-layout wrap>
      <v-flex xs4 v-for="(item, index) in networks" :key="index" class="mt-2 mb-2">
        <ShareNetwork
          :network="item.network"
          :url="url"
          :title="data.product_name"
          :description=" appName + ' proudly present: ' + data.product_name"
          :quote="appName"
          :hashtags="hashtags"
        >
          <v-btn icon class="pt-2 pb-2 pl-0 pr-0" :color="item.color">
            <v-icon x-large> {{ item.icon}}</v-icon> 
          </v-btn>
          <span class="text mt-2"> {{item.name}} </span>
        </ShareNetwork>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    name: 'SocialMedia',
    props : {
      data : {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        networks: [
          // { network: 'facebook', name: 'Facebook', icon:'fab fa-facebook-square', color:'#3b5998' },
          // { network: 'twitter', name: 'Twitter', icon:'fab fa-twitter-square', color:'#00acee'},
          // { network: 'line', name: 'LINE', icon:'fab fa-line', color:'#00b900'},
          // { network: 'whatsapp', name: 'WhatsApp', icon:'fab fa-whatsapp-square', color:'#00b900'},
          // { network: 'email', name: 'Email', icon:'fas fa-envelope-square', color:'#d44638'},
          { network: 'facebook', name: 'Facebook', icon:'mdi-facebook', color:'#3b5998' },
          { network: 'twitter', name: 'Twitter', icon:'mdi-twitter', color:'#00acee'},
          // { network: 'line', name: 'LINE', icon:'mdi-wechat', color:'#00b900'},
          { network: 'whatsapp', name: 'WhatsApp', icon:'mdi-whatsapp', color:'#00b900'},
          { network: 'email', name: 'Email', icon:'mdi-gmail', color:'#d44638'},
        ],
        url: '',
        hashtags: this.appName + ',fashion,men,women'
      }
    },
    computed: {},
    methods: {},
    mounted () {
      this.url = process.env.VUE_APP_BASE_URL + this.$route.fullPath
    }
  }
</script>

<style scoped>
  .social {
    padding: 20px;
    text-align: center;
    background-color: #fff;
  }

  .text {
    display: block;
    font-size: 0.8rem;
    color: #000;
  }

  .v-application a {
    color: transparent;
}
</style>
