<template>
  <div class="thumbnail-slider-wrapper">
    <!-- <div class="btn-prev" @click="prev">
      <v-icon color="#fff">mdi-chevron-left</v-icon>
    </div> -->
    <div id="thumbhor" ref="thumbhor" class="thumbnail">
      <div class="t-img-wrapper" v-for="(item, i) in media" :key="i" @click="moveTo(i)">
        <img v-if="item.type !== 'video'" :class="index === i ? 't-img-active' : 't-img'" :ref="'thumbhor-'+ i" :id="'thumbhor-'+ i" :src="item.url" :alt="item.url">

        <template v-else>
          <img v-if="videoThumbnail" :class="index === i ? 't-img-active' : 't-img'" :ref="'thumbhor-'+ i" :id="'thumbhor-'+ i" :src="videoThumbnail" :alt="videoThumbnail">
          <img v-else :class="index === i ? 't-img-active' : 't-img'" :ref="'thumbhor-'+ i" :id="'thumbhor-'+ i" :src="media[0].url" :alt="media[0].url">
        </template>
        
        <v-icon v-if="item.type === 'video'" class="icon-play" color="#C0C0C0">mdi-play-circle</v-icon>
      </div>
    </div>
    <!-- <div class="btn-next" @click="next">
      <v-icon color="#fff">mdi-chevron-right</v-icon>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Thumbnail',
  props: {
    media: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: Number,
      default: 0
    },
    videoThumbnail: {
      type: String,
      default: ''
    },
    forMobile: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    index (val) {
      this.moveTo(val)
    }
  },
  methods: {
    moveTo (index) {
      this.$emit('moveTo', index)
      this.scrollIntoView(index)
    },
    prev () {
      if (this.index !== 0) {
        this.$emit('moveTo', (this.index-1))
        this.scrollIntoView((this.index-1))
      }
    },
    next () {
      if ((this.index + 1) < this.media.length) {
        this.$emit('moveTo', (this.index+1))
        this.scrollIntoView((this.index+1))
      }
    },
    scrollIntoView (index) {
      setTimeout(()=> {
        this.scrollParentToChild(index)
      })
    },
    scrollParentToChild(index) {
      var parent = this.$refs.thumbhor
      let parentRect = this.$refs.thumbhor.getBoundingClientRect()
      let childRect = this.$refs['thumbhor-' + index][0].getBoundingClientRect()

      var parentViewableArea = {
        height: this.$refs.thumbhor.clientHeight,
        width:this.$refs.thumbhor.clientWidth
      }
  
      var isViewable = (childRect.left >= parentRect.left) && (childRect.right <= parentRect.left + parentViewableArea.width)

      if (!isViewable) {
        const scrollLeft = childRect.left - parentRect.left;
        const scrollBot = childRect.right - parentRect.right;
        if (Math.abs(scrollLeft) < Math.abs(scrollBot)) {
          parent.scrollLeft += scrollLeft
        } else {
          parent.scrollLeft += scrollBot;
        }
      }
    }
  }
}
</script>
<style scoped>
.thumbnail-slider-wrapper {
  /* height: 550px; */
  width: 100%;
  position: relative;
}
.thumbnail {
  /* max-height: 550px; */
  max-width: 100%;
  display: flex;
  overflow-x: auto;
}
.t-img {
  width: 65px;
  height: 60px;
  object-fit: cover;
  object-position: center;
  /* border-radius: 10px; */
  /* padding: 0px 4px; */
}
.t-img-active {
  width: 65px;
  height: 60px;
  object-fit: cover;
  object-position: center;
  /* border-radius: 10px; */
  border: 3px solid #000;
}
.btn-prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  padding: 4px 2px;
  gap: 5px;
  background: rgba(97, 97, 97, 0.7);
  border-radius: 0px 0px 2px 2px;
  cursor: pointer;
}

.btn-next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  padding: 4px 2px;
  gap: 5px;
  background: rgba(97, 97, 97, 0.7);
  border-radius: 0px 0px 2px 2px;
  cursor: pointer;
}

.t-img-wrapper {
  position: relative;
  cursor: pointer;
  min-height: 55px;
  margin: 0 4px;
}

.t-img-wrapper:last-child {
  margin-right: 0px;
}
.t-img-wrapper:first-child {
  margin-left: 0px;
}

.icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

</style>