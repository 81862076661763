<template>
  <div id="product">
    <div style="position:relative;">
      <slider class="sld" ref="slider" :options="options" @slide="slide">
        <slideritem v-for="(item,index) in data.url_media" :key="index">
          <div style="position:relative;">
            <div class="countdown" v-if="data && data.countdown">
              <Countdown :date="date" :isDetail="true" @finish="setCountdown()" />
            </div>
            <div v-if="item.type === 'image'" style="position:relative;">
              <img v-bind:src="item.url" class="img" :alt="item.url">
              <div class="shop-magnify" @click="$emit('fullscreen', item)">
                <v-btn fab small color="rgba(0, 0, 0)" elevation="0">
                  <v-icon color="#fff">mdi-magnify-plus-outline</v-icon>
                </v-btn>
              </div>
              <span v-if="data.status === 'sold'" class="shop-badge-notify" :style="soldColor ? {'background-color': soldColor} : {'background-color': 'rgb(218,41,28)'}"> {{ $t('shop.sold') }} </span>

              <span
                v-if="data.product_lable === 'COMING-SOON'"
                class="shop-badge-notify-soon"
              >
                SOON  
              </span>

              <span
                v-if="data.product_lable === 'ON-SALE'"
                class="shop-badge-notify-onsale"
              >
                ON SALE
              </span>

              <div
                v-if="data.category_menu && data.category_menu === 'Preorder'"
                :class="isMobile ? 'preorder-badge' : 'preorder-badge'"
              >
                PreOrder
              </div>
            </div>
            <div v-else class="slider-video">
              <video
                autoplay
                muted
                playsinline
                loop
                :poster="data.url_media[0].src"
                width="100%"
                height="500px"
                :id="'video-'+ index"
                :ref="'video-'+ index"
              >
                <source :src="item.url">
              </video>
              <span v-if="data.status === 'sold'" class="shop-badge-notify" :style="soldColor ? {'background-color': soldColor} : {'background-color': 'rgb(218,41,28)'}"> {{ $t('shop.sold') }} </span>
              
              <span
                v-if="data.product_lable === 'COMING-SOON'"
                class="shop-badge-notify-soon"
              >
                SOON  
              </span>

              <span
                v-if="data.product_lable === 'ON-SALE'"
                class="shop-badge-notify-onsale"
              >
                ON SALE
              </span>

            </div>
          </div>
        </slideritem>
        <div slot="loading">loading...</div>
      </slider>
      <v-btn
        class="mx-2 nav-left"
        color="#000"
        fab
        small
        @click="previous()"
      >
        <v-icon color="white">
          mdi-chevron-left
        </v-icon>
      </v-btn>

        <v-btn
        class="mx-2 nav-right"
        color="#000"
        fab
        small
        @click="next()"
      >
        <v-icon color="white">
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>

    <div class="pm-thumbnail">
      <Thumbnail :media="data.url_media" :index="index" @moveTo="moveTo($event)" :video-thumbnail="data.vid_thumbnail"/>
    </div>

    <div class="product-detail-m">
      <div style="float:right">
        <template v-if="!data.is_bundling">
          <v-btn v-if="updatedIsWishlist || data.is_wishlist" icon color="red">
            <v-icon medium>mdi-heart</v-icon>
          </v-btn>

          <v-btn v-else icon color="black" @click="$emit('openRemind','Add to Wishlist')">
            <v-icon medium>mdi-heart-outline</v-icon>
          </v-btn>
        </template>

          <v-btn icon color="black" @click="share()">
          <v-icon medium>mdi-share-variant</v-icon>
        </v-btn>
      </div>
      
      <h4> {{ data.product_name }} </h4>
    
      <p class="text"> 
        <span v-if="data.start_price == '0'" class="text-harga">
           {{ data.selling_price === 0 ? '???' : addComma(data.selling_price) + ' IDR' }}
        </span>
        <span v-if="data.start_price != '0'" class="text-harga">
           {{ addComma(data.start_price) }} IDR - {{ addComma(data.end_price) }} IDR
        </span>
        <span class="text-c mb-0" v-if="data.harga_coret && data.harga_coret !== '0'"> / {{ addComma(data.harga_coret) }} IDR </span>

        <br>
        <span class="text-sold mb-0" v-if="data.sold_qty && data.sold_qty !== '0' && brand === '9'">
          Terjual {{ data.sold_qty}} pcs
        </span>
      </p>

      <!-- <RatingLabel class="mb-2" :rating="data.rating_star" :detail="data"/> -->

      <v-btn v-if="data.status === 'sold' && !data.is_bundling" class="mt-0 mb-2" outlined tile width="30%" height="35px" @click="$emit('openRemind', 'Remind Me')">
        <span class="btn-text"> REMIND ME</span>
      </v-btn>

      <v-btn v-if="data.status === 'sold' && data.is_bundling" class="mt-0 mb-2" outlined tile width="30%" height="35px" :disabled="true">
        <span class="btn-text"> SOLD </span>
      </v-btn>

      <v-btn v-if="data.status !== 'sold'" outlined class="mt-0 mb-2" tile width="30%" height="35px" @click="chooseSize()">
        <span class="btn-text"> {{ $t('shop.add') }} </span>
      </v-btn>
    
      <div :class="data.is_bundling? 'mt-8' : ''">
        <p class="text2" v-if="showSizeList && (data && !data.countdown)"> {{ $t('shop.pick-variant') }}</p> 
        <table class="bordered" v-if="showSizeList && (data && !data.countdown)">
          <tbody v-if="data.status === 'sold' || data.product_lable === 'COMING-SOON'">
            <tr>
              <td v-if="data.status === 'sold'">{{ $t('shop.empty-product') }}</td>
              <td v-if="data.product_lable === 'COMING-SOON'"> COMING SOON</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item,index) in data.variant" :key="index" @click="submit(item)">
              <td v-if="data.start_price == '0'">{{ item.ukuran }}</td>
              <td v-if="data.start_price != '0'">
                <div style="display:flex;justify-content:space-between">
                  <div class="pl-8">{{ item.ukuran }}</div>
                  <div class="pr-8">IDR {{ addComma(item.selling_price)}}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <p class="text mt-5"> {{ data.product_code }} </p> -->
        <div class="text-d" v-html="description"></div>
      </div>

      <div v-if="data && data.related_product" class="mt-16">
        <h4> {{ data.judul_related }} </h4>
        <!-- <ProductSlider class="mb-8" :data="data.related_product"/> -->
        <Recommendation :shop="data.related_product" class="mb-8 "/>
        <!-- <RateReviews v-if="data && data.tampil_rating === 'YES' && data.rating_star !== '0'" class="mb-8" :rating="data.rating_star"/> -->
        <!-- <MobileDiscussion v-if="data && data.tampil_discussion === 'YES'" class="mb-8"/> -->
      </div>
    </div>

    <v-bottom-sheet
      v-model="openBundling"
      :fullscreen="true"
      scrollable
      inset
    >
      <v-sheet
        class="text-center"
      >
        <Bundling v-if="openBundling" :data="data" @close="openBundling = false" @addtocart="addToCartBundling($event)"/>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Countdown from '@/components/Countdown'
  import Bundling from '@/components/Bundling/Bundling'
  import { slider, slideritem } from 'vue-concise-slider'
  import Thumbnail from '@/components/Shop/Thumbnail'
  // import RatingLabel from '@/components/Rating/Rating/RatingLabel'
  // import RateReviews from '@/components/Rating/Rating/RateReviews'
  // import MobileDiscussion from '@/components/Rating/Discussion/MobileDiscussion'
  import Recommendation from '@/components/Recommendation/Recommendation'

  export default {
    name: 'ProductMobile',
    components: { Recommendation, slider, slideritem, Countdown, Bundling, Thumbnail},
    props: {
      data: {
        type: Object,
        default: () => {
          return {}
        }
      },
      isSuccess: {
        type: Boolean,
        default: false
      },
      updatedIsWishlist: {
        type: Boolean,
        default: false
      },
      playindicator: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      ...mapState([
        'isMobile',
        'token',
        'footer'
      ]),
      success () {
        return this.isSuccess
      },
      date () {
        if (this.data.countdown) {
          let t = this.data.end_at.split(/[- :]/);
          // Apply each element to the Date function
          let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
          return this.ceilValue((new Date(d)).getTime() / 1000)
        } else {
          return this.ceilValue((new Date()).getTime() / 1000)
        }
      },
      description () {
        return this.data && this.data.description ? '<div>'+ this.data.description +'</div>' : ''
      }
    },
    watch: {
      success (val) {
        if (val) {
          this.showSizeList = false
          this.close ()
        }
      }
    },
    data () {
      return {
        brand: process.env.VUE_APP_BRAND,
        showSizeList: false,
        options: {
          currentPage: 0,
          direction: 'horizontal',
          pagination: false,
          loop:true
        },
        soldColor: process.env.VUE_APP_LABEL_SOLD,
        index: 0,
        openBundling: false
      }
    },
    methods: {
      setCountdown () {
        this.$emit('countdown')
        this.data.countdown = false
      },
      play (index) {
        setTimeout(() => {
          let id = 'video-' + index
          let video = document.getElementById(id)

          if (video) {
            let videoplayer = this.$refs['video-' + index][0]
            if (this.playindicator[index].play) {
              videoplayer.pause()
              this.playindicator[index].play = false
            } else {
              videoplayer.play()
              this.playindicator[index].play = true
            }
          }
        }, 1000)
      },
      moveTo (index) {
        this.$refs.slider.$emit('slideTo', index)
      },
      next () {
        if (this.index === this.data.url_media.length - 1 ) {
          this.index = 0
        } 
        else {
          this.index += 1
        }
        this.$refs.slider.$emit('slideTo', this.index)
      },
      previous () {
        if (this.index === 0 ) {
          this.index = this.data.url_media.length - 1
        } 
        else {
          this.index -= 1
        }
        this.$refs.slider.$emit('slideTo', this.index)
      },
      playVideo (index) {
        if (this.playindicator[index].type === 'video') {
          this.play(index)
        } 
        else {
          this.setPauseVideo()
        }
      },
      setPauseVideo () {
        this.playindicator.forEach((item,index) => {
          if (item.play === true) {
            this.play(index)
          }
        })
      },
      slide (data){
        this.index = data.currentPage
        this.playVideo(this.index)
      },
      share () {
        this.$emit('share')
      },
      close () {
        this.showSizeList = false
      },
      chooseSize () {
        if (this.data && this.data.countdown) {
          this.$store.commit('setSnackbar', true)
          this.$store.commit('setText', 'Kamu tidak dapat melanjutkan pesanan, barang ini masih dalam perhitungan mundur (countdown)')
        } else {
          if (this.data.is_bundling) {
            this.openBundling = true
          } else {
            this.showSizeList = !this.showSizeList
          }
        }
      },
      // setImage() {
      //   if (this.data.url_image.length === 1) {
      //     this.data.url_image.push(this.data.url_image[0])
      //   }
      // },
      submit(item) {
        this.$emit('submit', item)
      },
      addToCartBundling ($event){
        this.openBundling = false
        this.$emit('addToCartBundling', $event)
      }
    },
    mounted () {
      // this.setImage()
    }
  }
</script>

<style scoped>
  #product {
    box-sizing: border-box;
    padding: 12px;
  }

  .img {
    width: 90%;
  }

  .nav-left {
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);
    z-index: 1;
    opacity: 0.2;
  }

  .nav-right {
    position: absolute;
    top: 50%;
    right: 6%;
    transform: translateY(-50%);
    z-index: 1;
    opacity: 0.2;
  } 

  .product-detail-m {
    margin-left: 5%;
    margin-right: 5%;
  }

  .text {
    font-size: 0.8rem;
  }

  .text2 {
    font-size: 0.8rem;
  }

  .text-detail {
    font-size: 0.9rem;
    cursor: pointer;
  }

  .underlined {
    text-decoration: underline;
  }

  .text-c {
    font-size: 0.8rem;
    color: #000000;
    text-decoration: line-through;
  }

  .text-harga {
    color: #da291c;
  }

  .text-sold {
    color: #808080 !important;
    /* font-weight: bold; */
  }

  .text-d >>> div {
    font-family: Helvetica;
    font-size: 0.8rem;
    margin: 0;
    text-align: justify;
  }

  .text-d >>> img {
    width: 85%;
    padding: 12px 0;
  }

  .bordered {
    text-align: center;
    font-family: Helvetica;
    margin: 10px 0 30px 0;
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .bordered tr {
    margin-bottom: 10px;
    cursor: pointer;
  }

  .bordered tr:hover {
    font-weight: bolder;
    background-color: #C0C0C0;
  }

  .bordered td {
    padding: 5px 2px; 
  }

  .countdown {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 20px;
    z-index: 100;
  }

  .pm-thumbnail {
    margin: 0 5% 4px 5%;
  }

  .shop-badge-notify{
    background-color: rgb(218,41,28);
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 3%;
    left: 6%;
    /* top: 3%;
    left: 2%; */
    z-index: 100;
  }

  .shop-badge-notify-soon{
    background-color: #e7a635;
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 3%;
    left: 6%;
    z-index: 100;
  }

  .shop-badge-notify-onsale{
    background-color: rgb(218,41,28);
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 3%;
    right: 6%;
    z-index: 100;
  }

  .shop-magnify {
    position: absolute;
    bottom: 3%;
    right: 8%;
    opacity: 0.2;
  }

  .preorder-badge {
    position: absolute;
    bottom: 5%;
    left: 8%;
    background-color: rgba(49, 53, 59, 0.68);
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    font-weight: bold;
    z-index: 100;
  }

  .sld >>> .slider-pagination-bullets{
    bottom: 20px !important;
  }

  .slider-video {
    position: relative;
  }

  .slider-video-button {
    position: absolute;
    top:5%;
    right:5%;
  }
  
</style>
