<template>
  <div class="shop-box">
    <div class="shop-item" @click="detail(item)">
      <v-carousel
        v-model="activeIndex"
        :class="isMobile ? 'my-carousel' : 'my-carousel-dekstop'"
        height="100%"
        :id="'row-' + index"
        :hide-delimiter-background="true"
        :hide-delimiters="true"
        :show-arrows="false"
      >
        <v-carousel-item>
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <ImageItem
              class="mt-2"
              :source="item.url_image"
              :alternate="item.product_code"
              customStyle="border-radius: 10px 10px 0 0;"
            ></ImageItem>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>

    <div xs12 style="min-height:70px">
      <div
        style="text-align:center;cursor:pointer;"
        @click="detail(item)"
      >
        <p class="text">{{ item.product_name }}</p>
        <p
          v-if="item.harga_coret && item.harga_coret !== '0'"
          class="text-through"
        >
          {{ addComma(item.harga_coret) }} IDR
        </p>
        <p  v-if="item.start_price == '0'" class="text" style="color: #da291c;">
          {{ item.selling_price === 0 ? '???' : addComma(item.selling_price) + ' IDR' }}
        </p>
        <p  v-if="item.start_price != '0'" class="text" style="color: #da291c;">
          {{ addComma(item.start_price) }} IDR -  {{ addComma(item.end_price) }} IDR
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from "@/components/ImageItem";
import { mapState } from "vuex";

export default {
  name: "RecommendationCard",
  components: { ImageItem },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  data() {
    return {
      activeIndex: 0,
      soldColor: process.env.VUE_APP_LABEL_SOLD,
    };
  },
  methods: {
    getTime(value) {
      let t = value.split(/[- :]/);
      // Apply each element to the Date function
      let d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      return this.ceilValue(new Date(d).getTime() / 1000);
    },
    detail(item) {
      this.toProduct(item);
    }
  },
};
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}

.my-carousel {
  background-color:#fafafa;
  border-radius: 10px 10px 0 0;
  min-height:260px;
}

.my-carousel-dekstop {
  background-color:#fafafa;
  border-radius: 10px 10px 0 0;
  min-height:450px;
}

.shop-box {
  box-shadow: 1px 2px #c0c0c0;
  border-radius: 10px;
  padding: 0;
}

.shop-item {
  position: relative;
  text-align: center;
}

.text {
  font-size: 0.8rem;
}

.text-sold {
  color: #000 !important;
}

.text-through {
  font-size: 0.8rem;
  color: #000000;
  text-decoration: line-through;
}

.v-window-x-transition-enter-active,
.v-window-x-reverse-transition-enter-active,
.v-window-y-transition-enter-active,
.v-window-y-reverse-transition-enter-active,
.v-window-x-transition-leave-active,
.v-window-x-reverse-transition-leave-active,
.v-window-y-transition-leave-active,
.v-window-y-reverse-transition-leave-active {
  transition: ease-in 500ms !important;
}
</style>
